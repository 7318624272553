/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_P5U0sGuoH",
  "aws_user_pools_web_client_id": "3cclmdhve7bnipqsak8umg5236",
  "oauth": {
      "domain": "bwfd-quadsim.auth.us-east-1.amazoncognito.com",
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "http://localhost:5000/",
      "redirectSignOut": "http://localhost:5000/",
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS"
};


// export default awsmobile;
module.exports = awsmobile;
